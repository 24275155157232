import { ClientForm } from './ClientForm';
import { DateInterval } from './DateInterval';
import { ParseKeys } from 'i18next';

export type PeopleFilter = {
  pageSize?: number;
  pageNumber?: number;
  searchTerm?: string;
};

export type PeopleResponse = {
  id: string;
  firstName: string;
  lastName: string;
  type: PeopleType;
  email?: string;
};

export enum PeopleType {
  Member = 0,
  Contact = 1,
}

export type DistributionMember = {
  distributionId: string;
  memberId: string;
  type: PeopleType;
  status: DistributionMemberStatus;
  statusUtc: string;
  notifiedOnUtc?: string;
  reminderSentUtc?: string;
};

export type DistributionSettings = {
  enableAcknowledgementSignature: boolean;
  automaticSendUncontrolledCopy: boolean;
};

export type DistributionPreferences = {
  scheduleType: DistrubutiuonSchedulingType;
  scheduleFixedDate: string | null;
  reminderFrequencyValue: number | null;
  reminderFrequencyInterval: DateInterval | null;
  notifyOnReady: boolean;
  notifyOnSuccessful: boolean;
  linkExpirationAmount: number | null;
  linkExpirationInterval: DateInterval | null;
  linkExpirationDate: string | null;
};

export enum DistrubutiuonSchedulingType {
  Immediate = 0,
  EffectiveDate = 1,
  FixedDate = 2,
}

export type DistributionCreateRequest = {
  clientFormId: string;
  clientFormVersion: number;
  settings: DistributionSettings;
  preferences: DistributionPreferences;
  members: Record<PeopleType, string[]>;
};

export type DistributionUpdateRequest = {
  settings: DistributionSettings;
  preferences: DistributionPreferences;
  members: Record<PeopleType, string[]>;
};

export type DistributionResponse = {
  id: string;
  clientFormId: string;
  clientFormVersion: number;
  startDate?: string;
  scheduleType: DistrubutiuonSchedulingType;
  status: DistributionStatus;
  members: DistributionMember[];
  settings: DistributionSettings;
  preferences: DistributionPreferences;
  createdBy: string;
  createdById: string;
};

export type DistributionInfoForContactResponse = {
  clientForm: ClientForm;
  errors: DistributionInfoForContactErrorsResponse;
};

type DistributionInfoForContactErrorsResponse = {
  expired: boolean;
  acknowledgedOn: string | null;
};

export enum DistributionMemberStatus {
  Created = 0,
  Pending = 1,
  Acknowledged = 2,
  Signed = 3,
}

export const DistributionMemberStatusKeys: Record<DistributionMemberStatus, ParseKeys<['distribution']>> = {
  [DistributionMemberStatus.Created]: 'distribution:status-names.created',
  [DistributionMemberStatus.Pending]: 'distribution:status-names.pending',
  [DistributionMemberStatus.Acknowledged]: 'distribution:status-names.acknowledged',
  [DistributionMemberStatus.Signed]: 'distribution:status-names.signed',
};

export enum DistributionStatus {
  InActive = 0,
  Active = 1,
  Completed = 2,
}

export enum DistributionStatusFilter {
  InActive = DistributionStatus.InActive,
  Active = DistributionStatus.Active,
  Completed = DistributionStatus.Completed,
  ActionNeededByCurrentUser = 99,
}

export const DistributionStatusFilterKeys = {
  [DistributionStatusFilter.InActive]: 'distribution:filters.inactive',
  [DistributionStatusFilter.Active]: 'distribution:filters.active',
  [DistributionStatusFilter.Completed]: 'distribution:filters.completed',
  [DistributionStatusFilter.ActionNeededByCurrentUser]: 'distribution:filters.action',
} as const;

export type MemberRecipient = { id: string; type: PeopleType; fullname: string };

type EmailRecipient =
  | {
      members: MemberRecipient[];
      emailAddresses?: string[];
    }
  | {
      members?: MemberRecipient[];
      emailAddresses: string[];
    };

export type UncontrolledCopyRequest = {
  clientFormId: string;
  clientFormVersion: number;
  recipients: EmailRecipient;
  subject: string;
  message: string;
  includeSelfCopy: boolean;
};
