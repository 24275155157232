import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import DropDownIcon from '../shared/icon/composite/DropDownIcon';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { currentClientAtom } from '../../recoil/atoms/Clients';
import LockIcon from './icon/LockIcon';
import BuildingIcon from './icon/BuildingIcon';
import { Access } from '../../models/Access';

type AccessDropdownProps = {
  onChange?: (value: Access) => void;
  selectedAccess: Access;
};

const AccessDropdown: FC<AccessDropdownProps> = (props) => {
  const { onChange, selectedAccess } = props;
  const [open, setOpen] = useState(false);
  const popupRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation('common');

  const onClick = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.preventDefault();
    setOpen(!open);
  };

  useEffect(() => {
    const mouseDownListener = (e: MouseEvent): void => {
      if (popupRef.current && !popupRef.current.contains(e.target as Node)) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', mouseDownListener);

    return () => {
      document.removeEventListener('mousedown', mouseDownListener);
    };
  }, []);

  const currentClient = useRecoilValue(currentClientAtom);

  const accessOptions = useMemo(() => {
    return [
      {
        id: Access.restricted,
        value: Access.restricted,
        text: t(`access-selector.items.restricted`),
      },
      {
        id: Access.intraClient,
        value: Access.intraClient,
        text: currentClient?.name,
      },
    ];
  }, [currentClient?.name, t]);

  const selectedAccessOption = useMemo(() => accessOptions.find((x) => x.id === selectedAccess), [accessOptions, selectedAccess]);

  const changeAccess = useCallback(
    (code: (typeof accessOptions)[Access]['id']) => {
      onChange && onChange(code);
    },
    [onChange],
  );

  return (
    <div ref={popupRef}>
      <div className="relative flex cursor-pointer select-none items-center" onClick={onClick}>
        {selectedAccessOption?.id === Access.restricted && <LockIcon className="h-6 w-6 flex-shrink-0 pr-2" />}
        {selectedAccessOption?.id === Access.intraClient && <BuildingIcon className="h-6 w-6 flex-shrink-0 pr-2" />}
        <div className="pr-2 font-medium">{selectedAccessOption?.text}</div>
        <DropDownIcon className="h-4 w-4" open={open} />
      </div>
      {open && (
        <div className="absolute left-4 px-2 py-1" onClick={() => setOpen(false)}>
          <ul className="border-1 min-w-48 border-gray-200 bg-white shadow-md">
            {accessOptions.map((lang) => (
              <li key={lang.value} className="cursor-pointer select-none px-3 py-1 hover:bg-gray-200" onClick={() => changeAccess(lang.value)}>
                {lang.text}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default AccessDropdown;
