import { ClientFormUserRole } from '../../models/ClientFormUserRoles';
import { Option } from '../Option';

export const roleOptions: Option<string, string | number>[] = [];
for (const [propertyKey, propertyValue] of Object.entries(ClientFormUserRole)) {
  if (!Number.isNaN(Number(propertyKey))) {
    continue;
  }
  roleOptions.push({ id: propertyValue.toString(), text: propertyKey, value: propertyValue as number });
}
