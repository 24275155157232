import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import User from '../../models/User';
import {
  useFloating,
  offset,
  flip,
  shift,
  autoUpdate,
  useClick,
  useDismiss,
  useInteractions,
  useRole,
  FloatingFocusManager,
} from '@floating-ui/react';
import { PeopleType } from '../../models/Distribution';
import UserListRenderer from './UserListRenderer';
import PlusIcon from '../shared/icon/PlusIcon';
import Checkbox from '../shared/form-control/Checkbox';
import { mouseAndKeyboardCallbackProps } from '../../utils/ComponentUtils';
import { ImageSize } from './ProfileImageStack';
import { SearchInput } from '../shared/form-control/SearchInput';
import { InputStyle } from '../shared/form-control/Input';
import { useTranslation } from 'react-i18next';
import { ClientFormUser } from '../../models/ClientFormUser';
import { ClientFormUserRoleValues } from '../../models/ClientFormUserRoles';
import Button, { ButtonSize, ButtonType } from '../shared/form-control/Button';

type Props = {
  users: User[];
  selectedUsers: ClientFormUser[];
  singleSelect?: boolean;
  assignedUserRole: ClientFormUserRoleValues;
  assignedFormSectionId?: string;
  assignedRequiredAction?: boolean;
  formUsers: ClientFormUser[];
  filterAssigned?: (value: ClientFormUser) => boolean;
  onUsersChanged: (users: ClientFormUser[]) => void;
};

const UsersSelect: FC<Props> = (props) => {
  const {
    users,
    selectedUsers,
    singleSelect,
    assignedUserRole,
    onUsersChanged,
    assignedFormSectionId,
    filterAssigned,
    formUsers,
    assignedRequiredAction,
  } = props;
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const [addedUsers, setAddedUsers] = useState<string[]>([]);
  const [removedUsers, setRemovedUsers] = useState<string[]>([]);
  const [selectedIds, setSelectedIds] = useState(selectedUsers.map((x) => x.id));

  const { t } = useTranslation('common');

  const { refs, floatingStyles, context } = useFloating({
    open: open,
    onOpenChange: setOpen,
    middleware: [offset(10), flip(), shift()],
    whileElementsMounted: autoUpdate,
    strategy: 'fixed',
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role]);

  useEffect(() => {
    if (open) return;

    setSearchValue('');

    setAddedUsers([]);
    setRemovedUsers([]);
    setSelectedIds(selectedUsers.map((x) => x.id));
  }, [open, selectedUsers]);

  const userOptions = useMemo(() => {
    const searchValueLower = searchValue.toLocaleLowerCase();

    let result = users
      .map((x) => ({
        id: x.id,
        text: `${x.firstName} ${x.lastName}`,
        value: PeopleType.Member,
      }))
      .filter((x) => !searchValue || x.text.toLocaleLowerCase().includes(searchValueLower));

    if (filterAssigned) {
      const usersToFilterOut = formUsers.filter(filterAssigned).map((x) => x.id);
      result = result.filter((x) => !usersToFilterOut.includes(x.id));
    }

    return result.sort((a, b) => a.text.localeCompare(b.text));
  }, [filterAssigned, formUsers, searchValue, users]);

  const selectedUsersChanged = useCallback(
    (id: string, value: boolean) => {
      if (singleSelect) {
        const added = users.find((x) => x.id === id)!;
        const usersResult = [{ ...added, role: assignedUserRole, requiresAction: assignedRequiredAction }];
        onUsersChanged(usersResult);
        return;
      }

      if (value) {
        setAddedUsers((prev) => [...prev, id]);
        setRemovedUsers((prev) => prev.filter((x) => x !== id));
        setSelectedIds((prev) => [...prev, id]);
      } else {
        setRemovedUsers((prev) => [...prev, id]);
        setAddedUsers((prev) => prev.filter((x) => x !== id));
        setSelectedIds((prev) => prev.filter((x) => x !== id));
      }
    },
    [assignedRequiredAction, assignedUserRole, onUsersChanged, singleSelect, users],
  );

  const onSave = useCallback(() => {
    const added = addedUsers
      .map((x) => users.find((u) => u.id === x)!)
      .map((x) => ({ ...x, role: assignedUserRole, formSectionId: assignedFormSectionId }));

    const usersResult = selectedUsers.concat(added).filter((x) => !removedUsers.includes(x.id));
    onUsersChanged(usersResult);
    setOpen(false);
  }, [addedUsers, assignedFormSectionId, assignedUserRole, onUsersChanged, removedUsers, selectedUsers, users]);

  return (
    <div {...mouseAndKeyboardCallbackProps((e) => e.stopPropagation())}>
      <span ref={refs.setReference} {...getReferenceProps()}>
        <PlusIcon className="text-gray-1 hover:bg-accent-1 bg-gray-6 h-6 w-6 cursor-pointer rounded-full p-[5px] transition-colors hover:text-white" />
      </span>

      {open && (
        <FloatingFocusManager context={context} modal={false}>
          <div
            ref={refs.setFloating}
            style={{ ...floatingStyles, zIndex: 9000 }}
            {...getFloatingProps()}
            className="max-h-[300px] overflow-y-auto rounded-[5px] bg-white p-2 pb-0 shadow-md"
          >
            <SearchInput
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              style={InputStyle.MINIMAL}
              wrapperClassName="m-2 top-0"
              placeholder={t('add-or-invite-modal.add.search')}
            />

            {userOptions.map((x) => (
              <div
                key={x.id}
                className="hover:bg-gray-6 my-1 flex cursor-pointer items-center gap-2 rounded-[5px] px-2 transition-colors"
                {...mouseAndKeyboardCallbackProps(() => selectedUsersChanged(x.id, !selectedIds.includes(x.id)))}
              >
                {!singleSelect && <Checkbox value={selectedIds.includes(x.id)} onChange={(value) => selectedUsersChanged(x.id, value)} />}
                <UserListRenderer {...x} size={ImageSize.XS} textHighlight={searchValue} />
              </div>
            ))}

            <div className="sticky bottom-0 flex items-center justify-end gap-2 bg-white py-2">
              <Button size={ButtonSize.S} type={ButtonType.SECONDARY} onClick={() => setOpen(false)}>
                {t('modals.cancel')}
              </Button>
              <Button size={ButtonSize.S} onClick={onSave}>
                {t('modals.save')}
              </Button>
            </div>
          </div>
        </FloatingFocusManager>
      )}
    </div>
  );
};

export default UsersSelect;
