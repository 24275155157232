import { FC } from 'react';
import { Option } from '../Option';
import { ImageSize, ProfileImageStack } from './ProfileImageStack';
import { PeopleType } from '../../models/Distribution';
import StringUtils from '../../utils/StringUtils';

const UserListRenderer: FC<Option<string, number> & { size?: ImageSize; textHighlight?: string }> = (props) => {
  const { id, text, value, size, textHighlight } = props;
  const [fn, ...lns] = text.split(' ');
  const ln = lns.join(' ');
  return (
    <div className="flex items-center py-1">
      <ProfileImageStack users={[{ firstName: fn, lastName: ln, id: value === PeopleType.Member ? id : undefined }]} size={size} />
      <div className="ml-2">{StringUtils.highlightText(text, textHighlight)}</div>
    </div>
  );
};

export default UserListRenderer;
